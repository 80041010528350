var Turbolinks = require("turbolinks")

// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/service-worker.js')
//     .then(function(registration) {
//         console.log('Registration successful, scope is:', registration.scope);
//     })
//     .catch(function(error) {
//         console.log('Service worker registration failed, error:', error);
//     });
// }

Turbolinks.start()

document.addEventListener("turbolinks:load", (event) => {
    if (ga) {
        ga("set", "location", event.data.url)
        ga("send", "pageview")
    }
    
    // Reference: http://www.html5rocks.com/en/tutorials/speed/animations/
    let header = document.querySelector('header')
    let last_known_scroll_position = 0;
    let ticking = false;
    let open = document.querySelector('#open');
    let close = document.querySelector('#close');

    open.addEventListener('click', (e) => {
        header.classList.add('open')
    })
    
    close.addEventListener('click', (e) => {
        header.classList.remove('open')
    })

    function doSomething(scroll_pos) {
        if (scroll_pos > 50) {
            header.classList.add('isStuck')
        } else {
            header.classList.remove('isStuck')
        }
    }

    window.addEventListener('scroll', (e) => {
    last_known_scroll_position = window.scrollY;

    if (!ticking) {
        window.requestAnimationFrame(() => {
            doSomething(last_known_scroll_position);
            ticking = false;
        });

        ticking = true;
    }
    });
})

if (document.readyState === "interactive") {
    const event = document.createEvent("Event");
    event.initEvent("turbolinks:load", true, true);``
    document.dispatchEvent(event);
}
